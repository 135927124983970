nav {
  background: $brown;

  .vivitz & {
    align-items: flex-start;
    padding: 0.5rem 0.75rem 0.2rem;
    background: transparent;
    transition: none;
  }

  .c-ice & {
    align-items: flex-start;
    padding: 0.5rem 0.75rem 0.2rem;
    background: $orange;
    transition: none;
    color: #000;
    @media screen and (max-width: 768px) {
      color: #fff;
    }
  }

  color: #fff;
  text-align: center;
  padding: 0.75rem;
  font-size: 1.5rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 50;

  transition: background 0.3s ease-in-out;

  .start-screen & {
    .vivitz & {
      background: transparentize(#fff, 0.5);
    }
  }

  display: flex;
  justify-content: space-between;

  .vivitz .start-screen & h1 {
    display: none;
  }

  h1 a {
    display: block;
    background: url(img/traktor-logo.svg) no-repeat left center / contain;
    z-index: 10000000;
    position: relative;
    .vivitz & {
      margin: 1rem 2rem 0;
      background: url(img/vivitz/vivitz-logo.png) no-repeat left center /
        contain;
      min-height: 7rem;
      transition: none;

      @media screen and (max-width: 700px) {
        min-height: 4rem;
        margin: 0.5rem 0.5rem 0;
        width: 80px;
      }
    }

    .c-ice & {
      background: url(img/c-ice/c-ice-logo.svg) no-repeat left center / contain;
    }
    .vivitz .start-screen & {
      min-height: 0rem;
    }

    text-indent: -7000px;
    margin: 0.25em 0;
    transition: opacity 0.3s ease-in-out;
    width: 140px;
  }

  div {
    display: flex;
    align-items: center;
  }

  .main {
    padding: 0.5rem 0;
    justify-content: center;
    ul {
      display: flex;
      align-items: center;
    }

    a {
      padding: 0 1rem;
      font-weight: bold;

      .vivitz & {
        color: $violet;
        border-radius: 4px;
        padding: 0.7rem 1.2rem 0.5rem;
        margin: 0 0.5rem;

        &:hover {
          //          background: transparentize(#fff, 0.55);
        }
      }
    }

    transition: opacity 0.3s ease-in-out;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .vivitz & {
      justify-content: space-between;

      .main {
        display: none;
      }
    }

    .vivitz .start-screen & {
      justify-content: flex-end;
    }
  }

  .languages {
    padding: 0.5rem 0;
    justify-content: flex-end;
    width: 180px;

    [lang="fr"] & {
      width: 200px;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }

    ul {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .active {
      font-weight: bold;
    }

    .vivitz & {
      color: $violet;
      width: 270px;

      a {
        border-radius: 4px;
        padding: 0.7rem 0.6rem 0.5rem;
        margin: 0 0.2rem;

        &:hover {
          //          background: transparentize(#fff, 0.55);
        }
      }
    }
  }
  .burgermenu__container {
    padding: 0 0 0.8rem 0;
    .overlay {
      position: fixed;
      transition: opacity 400ms ease;
      background: $overlay-color;
      top: $overlay-top;
      left: 0;
      width: 100%;
      height: 0;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      &.open {
        opacity: 1;
        visibility: visible;
        height: $container-height;
        z-index: 1000;
        & li {
          animation: $fadeIn 0.5s ease forwards;
          animation-delay: 0.35s;
        }
        & li:nth-of-type(2) {
          animation-delay: 0.4s;
        }
        & li:nth-of-type(3) {
          animation-delay: 0.45s;
        }
        & li:nth-of-type(4) {
          animation-delay: 0.5s;
        }
        & li:nth-of-type(5) {
          animation-delay: 0.55s;
        }
        & li:nth-of-type(6) {
          animation-delay: 0.6s;
        }
        .overlay-menu {
          position: relative;
          height: 70%;
          top: $overlay-position;
          transform: translateY($translate);
          text-align: center;
          margin: 0 auto;
          width: 100%;
          .languages-overlay {
            display: flex;
            justify-content: $justify-content;
            a {
              padding: 1rem 0.5rem;
              font-weight: normal;
              &:first-child {
                padding: 1rem 0.5rem 1rem 1rem;
              }
              &.active {
                font-weight: bold;
              }
            }
            span {
              padding: 1rem 0;
            }
          }
          & ul {
            list-style: none;
            padding: 0;
            margin: 0 auto;
            display: inline-block;
            position: relative;
            height: 100%;
            width: 100%;
            & li {
              display: block;
              // height: 25%;
              // height: calc(100% / 4);
              // min-height: 50px;
              position: relative;
              opacity: 0;
              border-bottom: 1px solid #fff;
              width: 100%;
              &:first-child {
                border-top: $border-top;
              }
              &:last-child {
                border-bottom: none;
              }
              & a {
                display: block;
                position: relative;
                color: #fff;
                padding: 1rem;
                font-weight: bold;
                text-align: $text-align;

                //  overflow: hidden;
              }
            }
          }
        }
      }
    }
    .burgermenu__toggle {
      width: $icon-width;
      height: $icon-height;
      margin-left: auto;
      margin-right: 0.3rem;
      margin-top: 0.3rem;
      position: relative;
      cursor: pointer;
      z-index: 1000000;
      &:hover {
        border-bottom: none;
      }
      &.white {
        .stroke {
          background-color: #fff;
        }
      }
      .stroke {
        @extend %transition-ease;
        display: block;
        position: absolute;
        height: $stroke-height;
        width: 100%;
        background-color: $stroke-color;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: $nav-toggle-transition;
        transition-property: transform, opacity, top, left;

        will-change: transform, opacity, top, left;

        &:nth-child(1) {
          top: 0;
          transform-origin: left center;
        }

        &:nth-child(2) {
          top: $icon-height * 0.5 - $stroke-height * 0.5;
          transform-origin: left center;
        }

        &:nth-child(3) {
          top: $icon-height - $stroke-height;
          transform-origin: left center;
        }

        .nav-is-open & {
          &:nth-child(1) {
            transform: rotate(45deg);
            top: -2px;
            left: 0px;
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 1.3125rem;
            left: 0px;
          }
        }
      }
    }
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    top: 20%;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    right: 20%;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

;@import "sass-embedded-legacy-load-done:5";