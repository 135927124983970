@use "sass:math";

@import "slides-base";

.slide.start-slide {
  overflow: hidden;
  //background: $start-slide-background;
  background-image: url("img/c-ice/C-ICE_millenials_blur-sq.jpg");
  background-position: center;
  background-size: cover;
  flex-flow: column nowrap;
  &.start-slide-fr h1 {
    background: url($start-slide-h1-image-url-fr) center center no-repeat;
    height: 250px !important;
  }
  h1 {
    background: url($start-slide-h1-image-url) center center no-repeat;

    background-size: contain;
    width: 350px;
    height: 150px;
    text-indent: -4000em;

    &.c-ice_header {
      width: 650px;
      height: 150px;
      margin-top: auto;
      margin-bottom: auto;
      left: -2rem;
      position: relative;
      @media screen and (max-width: 400px) {
        width: 350px;
        height: 150px;
        left: 0;
      }
    }
  }

  h2 {
    background-image: url(img/titel-hintergrund-gruen.png);
    background-size: 100% 100%;
    padding: 4rem 4rem 4.5rem 4rem;
    color: #fff;
    font-size: 35px;
    font-weight: 900;
  }

  .fb-link {
    position: absolute;
    bottom: 10%;
    left: 60%;
    color: #fff;
    font-weight: bold;
    font-size: 1.25rem;

    &:after {
      content: "";
      background: url(img/facebook-square.svg) no-repeat;
      background-size: contain;
      display: inline-block;
      width: 1.3em;
      height: 1.3em;
      position: relative;
      bottom: -3px;
      left: -1px;
    }
  }

  @media screen and (max-width: 400px) {
    h1 {
      width: 300px;
    }
    h2 {
      font-size: 25px;
    }
    .fb-link {
      left: 50%;
    }
  }

  .splash {
    position: absolute;
    z-index: 10;

    top: 0;
    left: 0;
    width: 500vw;
    height: 500vh;

    background-color: rgba(0, 0, 0, 0.5);

    opacity: 0;
    transition: opacity 0.3s linear;
    pointer-events: none;

    &--show {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .splash__image {
    $splash-height-abs: 633;
    $splash-width-abs: 904;

    &::before {
      content: "";
      display: block;
      padding-top: math.div($splash-height-abs, $splash-width-abs) * 100%;
    }

    position: absolute;
    top: 50vh;
    left: 50vw;

    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 15px 0px #333;
    background: url("img/popup-karma-de.jpg") center / contain no-repeat;
    cursor: pointer;

    [lang="fr"] & {
      background-image: url("img/popup-karma-fr.jpg");
    }

    width: 85vmin;

    @media screen and (min-width: 768px) {
      width: 70vmin;
    }

    @media screen and (min-width: 768px) and (orientation: landscape) {
      width: 70vmin;
    }
  }

  .splash__close {
    $splash-close-size: 1rem;
    content: "";
    position: absolute;
    top: -$splash-close-size;
    right: -$splash-close-size;
    padding: $splash-close-size;
    background: url("img/vivitz/popup-vivitz-close-x.png") center / contain
      no-repeat;

    @media screen and (min-width: 768px) {
      top: -$splash-close-size * 2;
      right: -$splash-close-size * 2;
      padding: $splash-close-size * 2;
    }
  }
}

.c-ice_hemp_small {
  height: 24rem;
  width: 24rem;
  position: absolute;
  //z-index: 100;
  animation-name: easeIn;
  animation-duration: 3s;
  animation-iteration-count: initial;
  transition: easeIn 200ms ease-in;
  //  display: none;
  img {
    max-width: 100%;
    height: 100%;
  }

  &.small-1 {
    transform: rotate(-50deg) translate(36%, 158%);
  }

  &.small-2 {
    transform: rotate(373deg) translate(-168%, 73%);
  }

  &.small-3 {
    transform: rotate(1deg) translate(71%, 37%);
  }

  &.small-4 {
    transform: rotate(-44deg) translate(129%, 37%);
  }

  &.small-5 {
    transform: rotate(373deg) translate(-168%, 73%);
  }
}

.c-ice_hemp_large {
  height: 38rem;
  width: 38rem;
  position: absolute;
  // z-index: 1000000;
  //transition: all 200ms ease-in;
  animation-name: easeIn;
  animation-duration: 3s;
  animation-iteration-count: initial;
  transition: easeIn 200ms ease-in;
  //display: none;
  //&.fadein {
  //  animation-name: easeIn;
  //  animation-duration: 3s;
  //  animation-iteration-count: initial;
  //  transition: easeIn 200ms ease-in;
  //}
  img {
    max-width: 100%;
    height: 100%;
  }

  &.large-1 {
    transform: rotate(51deg) translate(-13%, 109%);
  }

  &.large-2 {
    transform: rotate(9deg) translate(86%, 12%);
  }

  &.large-3 {
    transform: rotate(9deg) translate(-46%, -3%);
  }

  &.large-4 {
    transform: rotate(62deg) translate(-50%, 69%);
  }
  &.large-5 {
    transform: rotate(9deg) translate(86%, 12%);
  }
}

@keyframes easeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.c-ice__head {
  .c-ice-bottle__front {
    position: relative;
    z-index: 1000003;
    //   transition: pulse_animation 700ms ease-in-out;
    //   animation-name: pulse_animation;
    //   animation-duration: 6s;
    //   animation-iteration-count: infinite;
    img {
      width: 25rem;
    }
    @media screen and (max-width: 740px) {
      display: none;
    }
  }
  position: relative;
  left: -5rem;
  display: flex;
  @media screen and (max-width: 1180px) {
    left: 0;
  }
  @media screen and (max-width: 740px) {
    left: 0;
  }
}
/*
@keyframes pulse_animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}
*/

;@import "sass-embedded-legacy-load-done:16";