.gradient.layer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s ease-out;
  background: radial-gradient(
      ellipse 45vw 50vh,
      rgba(255, 255, 255, 0.3) 70%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  pointer-events: none;

  .wall-open & {
    opacity: 0;
  }
}

.wall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity 400ms ease-in-out;
  z-index: -2;
  //opacity: 0.5;

  div {
    transition:
      transform 1s ease-in-out,
      opacity 400ms ease-in-out;
    transform: translateY(-650px);
  }

  &#hanf {
    .top {
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
      }
    }
    .top-prev {
      @media screen and (max-width: 1359px) {
        background-position: bottom center;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom center;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom center;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
        background-position: bottom right 20%;
      }
    }
  }

  &#ingwer {
    .top {
      @media screen and (max-width: 1359px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom right;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
        background-position: bottom left 65%;
      }
    }
    .top-prev {
      @media screen and (max-width: 1359px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom right;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
      }
    }
  }

  &#minze {
    .top {
      @media screen and (max-width: 1359px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom right;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
        background-position: bottom left 65%;
      }
    }
    .top-prev {
      @media screen and (max-width: 1359px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom right;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
      }
    }
  }

  &#lemon {
    .top {
      @media screen and (max-width: 1359px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom right;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 85%;
        background-position: bottom right 12%;
      }
    }

    .top-prev {
      @media screen and (max-width: 1359px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom right;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
        background-position: bottom left 65%;
      }
    }
  }

  &#cold-brew {
    .top {
      @media screen and (max-width: 1359px) {
        background-position: bottom right 20%;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom right;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 85%;
        background-position: bottom right 30%;
      }
    }

    .top-prev {
      @media screen and (max-width: 1359px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom right;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
        background-position: bottom left 30%;
      }
    }
  }

  &#apple-mint {
    .top {
      @media screen and (max-width: 1359px) {
        background-position: bottom left;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom left;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom left;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
        background-position: bottom left 40%;
      }
    }

    .top-prev {
      @media screen and (max-width: 1359px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom right;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 85%;
        background-position: bottom right 12%;
      }
    }
  }

  &#green-tea {
    .top {
      @media screen and (max-width: 1359px) {
        background-position: bottom left;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom left;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom left;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
        background-position: bottom right 40%;
      }
    }

    .top-prev {
      @media screen and (max-width: 1359px) {
        background-position: bottom left;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom left;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom left;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
        background-position: bottom left 40%;
      }
    }
  }

  &#apple-elder {
    .top {
      @media screen and (max-width: 1359px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom right;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom right;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
        background-position: bottom right 20%;
      }
    }
    .top-prev {
      @media screen and (max-width: 1359px) {
        background-position: bottom left;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom left;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom left;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
        background-position: bottom right 40%;
      }
    }
  }

  &#rhubarb {
    .top {
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
        background-position: bottom right 55%;
      }
    }
    .top-prev {
      @media screen and (max-width: 1359px) {
        background-position: bottom center;
      }
      @media screen and (min-width: 1360px) {
        background-position: bottom center;
      }
      @media screen and (min-width: 1920px) {
        background-position: bottom center;
      }
      @media screen and (max-width: 420px) {
        background-size: auto 90%;
        background-position: bottom right 20%;
      }
    }
  }

  &#null {
    .vivitz & {
      .top {
        @media screen and (max-width: 420px) {
          background-size: auto 90%;
        }
      }
      .top-prev {
        @media screen and (max-width: 420px) {
          background-size: auto 90%;
          background-position: bottom right 55%;
        }
      }
    }
  }

  /*&#c-ice {
    .top {
      @media screen and (max-width: 1359px) {
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
      }
      @media screen and (min-width: 1360px) {
        //   background-position: top right;
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
      }
      @media screen and (min-width: 1920px) {
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .top-prev {
      @media screen and (max-width: 1359px) {
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
      }
      @media screen and (min-width: 1360px) {
        //   background-position: top right;
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
      }
      @media screen and (min-width: 1920px) {
        background-position:center bottom;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

  }
*/
  .top {
    height: 100%;
    transform: translateY(-250px);
    z-index: 2;
    position: relative;
    opacity: 1;

    @media screen and (max-width: 1359px) {
      background-position: center bottom;
      background-size: cover;
      background-repeat: no-repeat;
    }
    @media screen and (min-width: 1360px) {
      //   background-position: top right;
      background-position: center bottom;
      .traktorsmoothies &,
      .c-ice & {
        background-size: cover;
      }
      background-size: contain;
      background-repeat: no-repeat;
    }
    @media screen and (min-width: 1920px) {
      background-position: center bottom;
      .traktorsmoothies &,
      .c-ice & {
        background-size: cover;
      }
      background-size: contain;
      background-repeat: no-repeat;
    }
    //transition: background 500ms ease-in-out, transform 1s ease-in-out;
    &.fade {
      animation: fadeout 0.4s linear;
    }
  }

  .bg-multiply {
    height: 100%;
    width: 100%;
    // transform: translate(0, -15%);

    //   transform: translate(0, -50%);
    @media screen and (max-width: 600px) {
      //  transform: translate(-22%, 0);
    }

    @media screen and (max-width: 888px) {
      // transform: translate(0, 0);
      max-width: 100%;
      height: 100%;
      width: auto;
    }
  }

  //  #data-lemon {
  //    @media screen and (max-width: 420px) {
  //      transform: translate(-100%, 0);
  //    }
  //  }
  //
  //  #data-hanf {
  //    @media screen and (max-width: 420px) {
  //      transform: translate(-100%, 0);
  //    }
  //  }
  //
  //  #data-easy {
  //    @media screen and (max-width: 420px) {
  //      transform: translate(-100%, 0);
  //    }
  //  }

  .top-prev {
    position: absolute;
    height: 100%;
    transform: translateY(-250px);
    z-index: 1;
    //opacity: 1;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1;
    @media screen and (max-width: 1359px) {
      background-position: center bottom;
      background-size: cover;
      background-repeat: no-repeat;
    }
    @media screen and (min-width: 1360px) {
      //   background-position: top right;
      background-position: center bottom;
      .traktorsmoothies &,
      .c-ice & {
        background-size: cover;
      }
      background-size: contain;
      background-repeat: no-repeat;
    }
    @media screen and (min-width: 1920px) {
      background-position: center bottom;
      .traktorsmoothies &,
      .c-ice & {
        background-size: cover;
      }
      background-size: contain;
      background-repeat: no-repeat;
    }
    //transition: background 500ms ease-in-out, transform 1s ease-in-out;
  }

  @keyframes fadeout {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .bg {
    width: 100%;
    height: 400px;
    overflow: hidden;
    //opacity: 0.5;
    // transition: background 1s ease-in-out;
  }

  .bottom-prev {
    height: 100%;
    //opacity: 0.5;
    //transition: background 500ms ease-in-out, transform 1s ease-in-out;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 1;
    position: absolute;
  }

  .bottom {
    height: 100%;
    opacity: 1;
    position: relative;
    //transition: background 500ms ease-in-out, transform 1s ease-in-out;

    &.fade {
      animation: fadeout 0.4s linear;
    }
  }

  .wall-open & {
    .top {
      transform: translateY(-650px);
    }
    .top-prev {
      transform: translateY(-650px);
    }
  }
}

;@import "sass-embedded-legacy-load-done:14";