.slides {
  transition: transform 0.5s ease-in-out;

  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  overflow-x: hidden;
  animation-delay: 1s;
}

.slide {
  height: 100vh;
  width: 100vw;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  &.display-none {
    display: none;
  }
}

.slide:before {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s ease-out;
  position: absolute;
  background: rgba(#000, 0.5);
  opacity: 0;
  z-index: 15;
  pointer-events: none;

  .footer-open & {
    opacity: 1;
    pointer-events: auto;
  }
}

.slide .slide:before {
  display: none;
}

.slides.horizontal {
  height: 100vh;
  float: left;
  position: relative;
  overflow: visible;

  @for $i from 0 to 7 {
    .slide:nth-child(#{$i + 1}) {
      position: absolute;
      top: 0;
      left: #{$i * 100vw};
      z-index: 15;
    }
  }
}

;@import "sass-embedded-legacy-load-done:19";