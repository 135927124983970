@import "effect-layers-base";

.wall .bg {
  .kale & {
    color: $kale;
  }
  .ananas & {
    color: $ananas;
  }
  .himbeere & {
    color: $himbeere;
  }
  .mango & {
    color: $mango;
  }
  .granatapfel & {
    color: $granatapfel;
  }
  .orange & {
    color: $orange;
  }
  .ingwer-orange-mango & {
    color: #3eb3ab;
  }
  .himbeere-cranberry & {
    color: #cd006d;
  }
  .mango-passionsfrucht & {
    color: #d43b14;
  }
  .ananas-banane-orange & {
    color: #d76d00;
  }
  .kurkuma-mango-limette & {
    color: #651453;
  }
  .kiwi-orange-limette & {
    color: #4c8500;
  }
}

;@import "sass-embedded-legacy-load-done:12";