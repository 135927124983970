@import "smoothies_base";

.button {
  background: $orange;
  color: #fff;
  font-weight: bold;
  display: inline-block;
  padding: 0.5rem 1rem;
  z-index: 10000;
}

.slide[data-body-class="smoothie-screen"]:nth-child(odd) .bottle {
  order: 1;
}

.smoothie.c-ice {
  .bottle {
    //background-image: url(img/c-ice/c-ice.png);
    background-image: url(img/c-ice/C-ICE_Can_Legaliced_Web4.png);
    // animation-name: pulse_animation;
    // animation-duration: 6s;
    // animation-iteration-count: infinite;
    // transition: pulse_animation 700ms ease-in-out;
    margin-right: 1rem;
  }
  .bubble {
    @media screen and (max-width: 340px) {
      background-size: 100% 75%;
      font-size: 16px;
      padding: 2rem 2rem 4rem 2rem;
      margin: 0rem -2rem -2.5rem -2rem;
    }
  }
  .light {
    @media screen and (max-width: 340px) {
      font-size: 13px;
      hyphens: auto;
    }
  }
  h2 {
    background-image: url(img/stoerer.svg);
  }

  .ingredients {
    @media screen and (max-width: 340px) {
      top: -10px;
    }
  }
}

.dark {
  font-size: 1.5rem;
}
/*

@keyframes pulse_animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}
*/

;@import "sass-embedded-legacy-load-done:23";