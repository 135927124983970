@import "scss/_reset";

body {
  box-sizing: border-box;
  &.nav-is-open {
    //   position: fixed;
  }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

$kale: #4c8500;
$brown: #6f5332;
$hemp: #66a17b;
$ananas: #d76d00;
$himbeere: #cd006d;
$mango: #d43b14;
$granatapfel: #523275;
$orange: #ef8f24;

$violet: #468c9d;
$footer-transparency: 0.2;

$wall-bottom-image-url: "img/c-ice/bg-green.png";
$wall-top-image-url: "img/c-ice/bg-green.png";
$start-slide-image-url: "img/c-ice/bg-green.png";
$start-slide-h1-image-url: "img/c-ice/claim-de.png";
$start-slide-h1-image-url-fr: "img/c-ice/claim-fr.png";
$start-slide-background: $orange;

$icon-height: 1.55rem;
$icon-width: 2rem;
$icon-margin: 1rem;
$stroke-height: 0.3rem;
$stroke-width: 100%;

// Navigation
$stroke-color: black;
$overlay-color: transparentize($violet, $footer-transparency);
$text-align: middle;
$justify-content: center;
$container-height: 100%;
$overlay-position: 30%;
$border-top: none;
$fadeIn: fadeInTop;
$translate: -50%;
$overlay-top: 0;

// Products
$title-color: $orange;
$title-position: 6%;
$title-position-small: 6%;

$nav-toggle-transition: 0.25s ease-in-out;

%transition-ease {
  transition: 0.2s ease;
}

@font-face {
  font-family: "AmsiProCond";
  src: url(fonts/Stawix_-_AmsiProCond-Regular.woff);
  font-weight: normal;
}
@font-face {
  font-family: "AmsiProCond";
  src: url(fonts/Stawix_-_AmsiProCond-Bold.woff);
  font-weight: bold;
}
@font-face {
  font-family: "AmsiProCond";
  src: url(fonts/Stawix_-_AmsiProCond-Black.woff);
  font-weight: 900;
}

html,
body {
  font-family: AmsiProCond, sans-serif;
  overflow-y: hidden;
  overflow-x: hidden;
  color: $brown;
}

html.impressum {
  overflow-y: visible;
  .wrappy {
    position: static;
    overflow: visible;
  }

  .text-impressum {
    margin: 2rem;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
    margin-top: 0.5rem;
  }
  h3 {
    font-size: 1.25rem;
    margin-top: 0.5rem;
  }
  p {
    font-weight: normal;
  }
}

.wrappy {
  display: inline-block; /* ensure .smoothies margin does not move outside box */
  width: 100%;
  overflow: hidden;
  position: fixed; /* necessary for iOS -- will not stop scrolling on touch otherwise */
}

.bubble {
  color: #fff;
  font-size: 35px;
  padding: 4rem 3rem 4rem 3rem;
  text-align: center;
  font-weight: 900;
  background-image: url(img/stoerer.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0 -2rem 1rem -2rem;

  @media screen and (max-width: 768px) {
    font-size: 22px;
    padding: 3rem 1rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 18px;
  }
}

p {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
}

@import "scss/navigation";
@import "scss/footer";
@import "scss/effect-layers";
@import "scss/slides_c-ice.scss";
@import "scss/smoothies_c-ice";
@import "scss/content-screens-c-ice";
@import "scss/mixins";

.f3cc {
  --f3cc-background: #020202;
  --f3cc-foreground: #fff;
  --f3cc-button-background: #444;
  --f3cc-accept-background: #568c69;
  --f3cc-button-foreground: #fff;
}
